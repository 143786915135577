import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MilestoneData } from './MilestoneData';

export const MilestoneCompleted = ({ isJobCompleted, milestone, isHomeowner, isFirstMilestone, isLastMilestone }) => {
  const [show, setShow] = useState(false);
  return (
    <CircleCompletedContainer
      isHomeowner={isHomeowner}
      isJobCompleted={isJobCompleted}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {isHomeowner && <FontAwesomeIcon icon="check" />}

      <MilestoneData
        milestone={milestone}
        show={show}
        isFirstMilestone={isFirstMilestone}
        isLastMilestone={isLastMilestone}
      />
    </CircleCompletedContainer>
  );
};

const CircleCompletedContainer = styled.div`
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  ${props => `
        height: ${props.isHomeowner ? '18px' : '12px'} ;
        width: ${props.isHomeowner ? '18px' : '12px'} ;
        background-color: ${props.isJobCompleted ? colors.green300 : colors.blue300};
      `}
`;
