import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { MilestoneData } from './MilestoneData';

export const MilestoneNotStarted = ({
  isNextMilestoneFromRef,
  statusMilestoneRef,
  milestone,
  isHomeowner,
  isFirstMilestone,
  isLastMilestone,
}) => {
  const [show, setShow] = useState(false);
  const isMilestoneNotStartedBeforeOneCompleted = isNextMilestoneFromRef && statusMilestoneRef === 'completed';

  return (
    <CircleNotStartedContainer
      isHomeowner={isHomeowner}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      isNextMilestoneFromRef={isNextMilestoneFromRef}
      statusMilestoneRef={statusMilestoneRef}
    >
      <MilestoneData
        milestone={milestone}
        show={isMilestoneNotStartedBeforeOneCompleted || show}
        isFirstMilestone={isFirstMilestone}
        isLastMilestone={isLastMilestone}
        newTextStatus={isMilestoneNotStartedBeforeOneCompleted ? 'Expected to start next.' : null}
      />
    </CircleNotStartedContainer>
  );
};

const CircleNotStartedContainer = styled.div`
  border-radius: 50%;
  color: ${colors.white};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${props => `
  background-color: ${props.isHomeowner ? colors.blue100 : colors.gray500};
      height: ${props.isHomeowner ? '18px' : '12px'};
      width: ${props.isHomeowner ? '18px' : '12px'};
      &::after {
        content: ' ';
        position: absolute;
        border-radius: 10px;
        background-color: ${colors.blue300};
        width: ${props.isHomeowner ? '10px' : '8px'};
        left: ${props.isHomeowner ? '4px ' : '2px'};
        top: ${props.isHomeowner ? '4px ' : '2px'};
            height:${
              props.isNextMilestoneFromRef && props.statusMilestoneRef === 'completed'
                ? props.isHomeowner
                  ? '10px'
                  : '8px'
                : '0'
            };
      }
    `}
`;
