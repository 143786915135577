import React from 'react';
import cn from 'classnames';
import { fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { UpcomingCardPlaceholder } from '../Loading';
import { Section } from '../index';
import { EmptyState } from '../EmptyState';
import { useCommunities, useTaskTypes } from '../../index';
import { UpcomingCard } from './UpcomingCard';
import { getUserDomain, hasPermissionForAssignedTask } from '../../utils';
import { FilterSets } from '../../Filters/FilterSets';
import { useFilterContext } from '../../Filters/Filters.context';
import { FilterImplementation } from '../../Filters/Implementation/Filter.implementation';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

export const getAssigneeProps = (appContext, controller) => {
  const { appState, hasPermission } = appContext;
  const loggedUserId = appState.getIn(['user', '_id']);
  return task => {
    const isAssignable = hasPermissionForAssignedTask('task_update_installer', hasPermission, task, loggedUserId);
    const currentUser = fromJS({
      _id: task.getIn(['assigneeAccount', 'installerId']),
      name: task.getIn(['assigneeAccount', 'installerName']),
      status: task.getIn(['assigneeAccount', 'installerStatus']),
    });
    return {
      index: task.get('key'),
      inputSize: '200px',
      placeholder: 'Choose Installer',
      hasPermission: isAssignable,
      users: appState.getIn(['account', 'users']),
      options: appState.getIn(['account', 'assigneesActive']),
      loggedUserId,
      currentUser,
      onChange: assignee => controller.assignUpcomingTask(task, assignee),
    };
  };
};

export const UpcomingSection = ({
  context,
  appContext,
  service,
  linkToTaskDetails,
  linkToJob,
  linkUpcomingTasks,
  linkCommunityDetails,
}) => {
  const { user, assignees } = appContext.appState.toObject();
  const { state, controller, containerRef, communities, taskTypes } = context;
  const { upcomingTasks } = state.toObject();
  const filterContext = useFilterContext(FILTER_NAME.UPCOMING);
  const { isFiltering } = filterContext;
  const title = isFiltering ? 'No Matches Found' : 'No Upcoming Tasks';
  const body = isFiltering
    ? "When there's a Task that meets the filter, it will appear here."
    : 'When a future Task is assigned to you, it will appear here.';
  const icon = isFiltering ? 'wrench' : 'clipboard-list';
  const upcomingIsLoading = upcomingTasks.getIn([0, 'empty']);
  const groups = React.useMemo(() => upcomingTasks.groupBy(task => task.get('expectedStartDate')), [upcomingTasks]);
  const { isTrade } = getUserDomain(user);
  const isEmptyUpcomingTasks = !upcomingIsLoading && upcomingTasks.size === 0;
  const lookups = { communities, taskTypes, assignees };

  return (
    <Section className="d-flex flex-column" data-testid="dashboard-upcoming-tasks">
      <div className="d-flex flex-row justify-content-between align-items-center position-relative">
        <h5
          className={cn({
            invisible: isEmptyUpcomingTasks,
            'section-title': !isEmptyUpcomingTasks,
          })}
        >
          Upcoming Tasks
        </h5>
        <FilterImplementation
          filterContext={filterContext}
          lookups={lookups}
          title="Upcoming Tasks Filters"
          style={{ top: '100px' }}
        >
          <FilterSets.SectionDropdown filterContext={filterContext} />
        </FilterImplementation>
      </div>
      <EmptyState visible={isEmptyUpcomingTasks} icon={icon} title={title} body={body} />
      <Container
        ref={containerRef}
        style={{ flex: '1 1 auto' }}
        fluid="lg"
        className={cn({ 'd-none': isEmptyUpcomingTasks })}
      >
        <Row className="pt-2">
          <Col sm={12}>
            {upcomingIsLoading && <UpcomingCardPlaceholder howMany={4} />}
            {!upcomingIsLoading && upcomingTasks.size > 0 && (
              <>
                {groups
                  .map((upcomingTasks, key) => (
                    <UpcomingCard
                      tasks={upcomingTasks}
                      date={key}
                      key={key}
                      controller={controller}
                      isTrade={isTrade}
                      linkToTaskDetails={linkToTaskDetails}
                      linkToJob={linkToJob}
                      assigneeProps={getAssigneeProps(appContext, controller)}
                      linkCommunityDetails={linkCommunityDetails}
                    />
                  ))
                  .valueSeq()}
              </>
            )}
          </Col>
          <Link className={cn('btn btn-link mx-auto', { 'd-none': upcomingTasks.size < 4 })} to={linkUpcomingTasks}>
            View all
          </Link>
        </Row>
      </Container>
    </Section>
  );
};
