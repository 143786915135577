import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

export const InProgressData = ({ milestone, show, isFirstMilestone, isHomeowner, isLastMilestone, width, hover }) => {
  const { name } = milestone.toObject();

  return (
    <InProgressDataContainer
      isFirstMilestone={isFirstMilestone}
      isHomeowner={isHomeowner}
      isLastMilestone={isLastMilestone}
      data-testid="in-progress-data-container"
      width={width}
      hover={hover}
    >
      <InProgressName >{name}</InProgressName>
      <InProgressStatus show={show}>In Progress</InProgressStatus>
    </InProgressDataContainer>
  );
};

const InProgressDataContainer = styled.div`
  ${props => `
      text-align: center;
        ${!props.isHomeowner &&
          `
          text-align: ${props.isFirstMilestone ? 'left' : props.isLastMilestone ? 'right' : 'center'};
          ${props.isLastMilestone && 'right:0px;'};
          ${props.isFirstMilestone && 'left:0px;'};
        `};
        top: ${!props.isHomeowner ? '15px' : '90px'};
        display:${ props.width > 85 ? 'flex': props.hover?'flex':'none'  };
        width: ${ props.width < 85 ? '120px' :  props.width > 130 ?'90px':'70px'  };
        `}
  position: absolute;
  flex-direction: column;
`;

const InProgressStatus = styled.span`
  ${props => `
      visibility:${props.show ? 'visible' : 'hidden'};
  `}
    color: ${colors.green400};
    font-size: 12px;
  `;

  const InProgressName = styled.div`
  color: ${colors.gray800};
  font-size: 14px;
  font-weight: bold;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: normal; 
  max-width: 100%; 
  line-height: 1.5em;
  word-break: break-word; 
  hyphens: auto;

`;
