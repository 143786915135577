import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import * as actions from './UsersPageActions';
import { useAppContext } from 'app/App.context';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

export const emptyState = fromJS({
  users: [],
  totalCount: 10,
  customRoles: [],
});

export const UsersPageContext = () => {
  const appContext = useAppContext();
  const { appState } = appContext;
  const filterContext = useFilterContext(FILTER_NAME.USERS);
  const { filterState } = filterContext;
  const loaderRef = React.useRef();
  const [state, controller] = useController(actions, emptyState, { ...appContext, loaderRef, filterState });
  const currentUserId = appState.getIn(['user', '_id']);
  const roleUser = appState.getIn(['user', 'role']);
  const filter = filterState.get('values');
  const [oldFilter, setOldFilter] = React.useState(filter);

  React.useEffect(() => {
    if (filter !== oldFilter) {
      setOldFilter(filter);
      controller.loadMoreRows({ reset: true }).then(() => {
        setTimeout(() => loaderRef.current?.resetLoadMoreRowsCache(true), 1);
      });
    }
  }, [controller, filter, oldFilter]);

  React.useEffect(() => {
    controller.readCustomRoles();
  }, [controller]);

  return { state, controller, loaderRef, currentUserId, roleUser, filterContext };
};
