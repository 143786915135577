import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, useTimeAgo } from '@tradetrax/web-common';
import { emptyStringToNull } from '@tradetrax/web-common/lib/EditableField';
import { UserStatusCard } from '@tradetrax/web-common/lib/UserStatusCard';

const schema = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\+?[1-9]\d{9,14}$/, 'Invalid phone format')
    .transform(emptyStringToNull)
    .nullable(),
});

const CARD_TEXT = user => {
  const fullName = `${user.get('firstName')} ${user.get('lastName')}`;
  const firstLogin = useTimeAgo(user.get('firstLogin'));
  return {
    'invitation-sent': 'Pending to accept.',
    active: `First login ${firstLogin}`,
    inactive: `Homeowner ${fullName} has been deactivated.`,
    deactivated: `Homeowner ${fullName} has been deactivated.`,
    'invitation-accepted': `Pending on ${fullName} to log in.`,
    'profile-created': 'Pending to send invitation.',
    'sending-invite': 'Sending Invite',
  };
};

const VALID_STATUS = ['profile-created', 'invitation-sent', 'inactive', 'deactivated'];

const CardActionButton = ({ status, statusCardAction }) => {
  if (status === 'sending-invite')
    return (
      <>
        <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true" />
        'Sending...'
      </>
    );
  if (VALID_STATUS.indexOf(status) < 0) return null;
  return (
    <button className="btn btn-link font-size-14 p-0 pb-1" onClick={statusCardAction}>
      {status === 'profile-created' && 'Send Invite'}
      {status === 'invitation-sent' && 'Resend Invite.'}
      {status === 'inactive' && 'Reactivate Member.'}
      {status === 'deactivated' && 'Reactivate Member.'}
    </button>
  );
};

export function MembersTab({ homeownerDetails, controller }) {
  const { firstName, lastName, email, phone, status } = homeownerDetails.toObject();
  const {
    register,
    trigger,
    getValues,
    errors,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { phone: '' },
    resolver: yupResolver(schema),
  });
  const isPhoneSet = !!phone;
  const hasPhone = !!getValues('phone');
  const isInactive = status === 'deactivated';
  const statusCardAction = isInactive ? controller.toggleUserActive : controller.sendInvite;

  const onBlur = async ({ target }) => {
    const field = target.name;
    const isValid = await trigger(field);
    const value = getValues(field);
    if (isValid && value) controller.updatePhoneNumber(value);
  };
  return (
    <>
      <p className="my-3 pb-4 ml-4 pt-2">Only the Homeowner can edit this information.</p>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-column ml-4 w-100">
          <div className="d-flex flex-row mb-4" style={{ maxWidth: '600px' }}>
            <div className="w-50 d-flex flex-column">
              <span className="font-size-14 text-gray-400">First Name</span>
              <span className="font-size-16">{firstName}</span>
            </div>
            <div className="w-50 d-flex flex-column">
              <span className="font-size-14 text-gray-400">Last Name</span>
              <span className="font-size-16">{lastName}</span>
            </div>
          </div>
          <div className="d-flex flex-row mb-4" style={{ maxWidth: '600px' }}>
            <div className="w-50 d-flex flex-column">
              <span className="font-size-14 text-gray-400">Email</span>
              <span className="font-size-16">{email}</span>
            </div>
            <div className="w-50  d-flex flex-column mr-3" style={{ maxWidth: '280px' }}>
              <span className="font-size-14 text-gray-400">Phone</span>
              {isPhoneSet ? (
                <span className="font-size-16">{phone}</span>
              ) : (
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="phone"
                    size="lg"
                    placeholder="Ex. 602 555 7890"
                    ref={register}
                    isInvalid={!!errors.phone}
                    isValid={hasPhone && dirtyFields.phone && !errors.phone}
                    onBlur={onBlur}
                  />
                  <span className={'d-block text-gray-400'}>
                    * Optional
                    <OverlayTrigger placement="bottom-start" overlay={popover}>
                      <FontAwesomeIcon icon="square-info" className="ml-2 text-primary cursor-pointer" />
                    </OverlayTrigger>
                  </span>
                  <Form.Control.Feedback type="invalid" className={cn({ 'd-inline': !!errors.phone })}>
                    <FontAwesomeIcon icon="circle-exclamation" />
                    {errors.phone && ` ${errors.phone.message}`}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">
                    <FontAwesomeIcon icon="check" /> Phone number is valid
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <UserStatusCard user={homeownerDetails}>
          {CARD_TEXT(homeownerDetails)[status]}
          <div className="ml-4">
            <CardActionButton status={status} statusCardAction={statusCardAction} />
          </div>
        </UserStatusCard>
        <button
          className={cn('my-5 mr-5 btn btn-link', { 'text-danger': !isInactive, 'text-success': isInactive })}
          onClick={() => controller.toggleUserActive()}
        >
          {isInactive ? (
            <>
              <FontAwesomeIcon icon="circle-plus" className="mr-2 " /> Reactivate Homeowner
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="ban" className="mr-2 " /> Deactivate Homeowner
            </>
          )}
        </button>
      </div>
      <Divider />
    </>
  );
}

const Divider = styled.hr`
  border-top: 1px solid ${colors.gray500};
  width: 99.5%;
`;

const popover = (
  <Popover id="popover-trx">
    <Popover.Content>
      <span className="text-gray-400">
        Since there's no Phone set, you can add it. Make sure the phone number is correct.
      </span>
    </Popover.Content>
  </Popover>
);
