import React from 'react';
import { Section } from '../index';
import { TodoSelectDate } from '../../ToDo';
import { ToDoCard } from './ToDoCard';
import { FilterSets } from '../../Filters/FilterSets';
import { useCommunities, useTaskTypes } from '../../index';
import { getUserDomain, plural } from '../../utils';
import { useEvent } from '../../useEvents';
import { useFilterContext } from '../../Filters/Filters.context';
import { FilterImplementation } from '../../Filters/Implementation/Filter.implementation';
import { FILTER_NAME } from '../../Filters/Implementation/filter.name';

export const ToDoSection = ({ context, appContext, service }) => {
  const { state, controller, communities, taskTypes } = context;
  const { assignees, user } = appContext.appState.toObject();
  const { isTrade } = getUserDomain(user);
  const path = isTrade ? 'confirmationRequests' : 'updateToday';
  const totalCountUpdate = state.getIn(['toDo', path]);
  const totalCountStart = state.getIn(['toDo', 'startToday']);
  const totalCountFinish = state.getIn(['toDo', 'finishToday']);
  const filterContext = useFilterContext(FILTER_NAME.TO_DO);
  const lookups = { communities, taskTypes, assignees };
  const { filterState } = filterContext;
  const selectedDate = filterState.getIn(['sessionValues', 'selectedDate']);

  const toDoItems = [
    {
      name: isTrade
        ? 'Start Date Confirmation Requests'
        : `${plural(totalCountUpdate, 'Task', 'Tasks')} Expected to Update`,
      totalCount: totalCountUpdate || 0,
    },
    { name: `${plural(totalCountStart, 'Task', 'Tasks')} Expected to Start`, totalCount: totalCountStart || 0 },
    { name: `${plural(totalCountFinish, 'Task', 'Tasks')} Expected to Finish`, totalCount: totalCountFinish || 0 },
  ];

  useEvent('task-counters:update', controller.conciliateCounters);

  return (
    <Section className="d-flex flex-column py-2" data-testid="todo-section">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="section-title">To-Dos</h5>
        <div className="d-flex">
          <div className="btn btn-link mb-3 ml-auto font-weight-bold text-decoration-none">
            <TodoSelectDate {...filterContext} />
          </div>
          <div className="mt-2">
            <FilterImplementation filterContext={filterContext} lookups={lookups} title="To-Dos Filters">
              <FilterSets.SectionDropdown filterContext={filterContext} />
            </FilterImplementation>
          </div>
        </div>
      </div>
      <ul className="list-unstyled mt-2 mb-0">
        {toDoItems.map((item, index) => (
          <ToDoCard toDoItem={item} index={index} selectedDate={selectedDate} key={index} />
        ))}
      </ul>
    </Section>
  );
};
