import { fromJS, List } from 'immutable';
import { buildersService } from 'services';
import { AddUserModal } from './AddUserModal';
import { markAsSideEffect } from '@tradetrax/web-common';
import moment from 'moment';
import { ResendInviteModal } from '@tradetrax/web-common/lib/UserProfile/ResendInviteModal';
import { DeactivateUserModal } from '@tradetrax/web-common/lib/Modal/DeactivateUserModal';

markAsSideEffect(resendInvite);
export async function resendInvite(user, path = ['user']) {
  const { isCancel, ...rest } = await this.modal.open(ResendInviteModal, { user });

  if (isCancel) return;

  const userId = user.get('_id');
  const { viaEmail, viaSMS } = rest;
  const params = { userId };
  const form = {};
  const query = {};

  if (viaEmail) {
    form.email = user.get('email');
    query.email = true;
  }
  if (viaSMS) {
    form.phone = user.get('phone');
    query.sms = true;
  }

  this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', true))]);

  return buildersService
    .sendInviteUser(form, { params, query })
    .then(() => {
      this.alert.success({
        message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
      });
      const lastActivity = moment().toISOString();
      this.controller.dispatch([
        state => state.updateIn(path, user => user.merge({ lastActivity, sendingInvite: false })),
      ]);
    })
    .catch(err => {
      this.controller.dispatch([state => state.updateIn(path, user => user.set('sendingInvite', false))]);
      this.alert.error({ message: 'There was a problem sending this invite. Please try again.' });
    });
}

export function loadMoreRows({ reset = false, startIndex = 0, stopIndex = 9 }) {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam(startIndex, stopIndex, filter);

  return buildersService
    .listUsers({}, { query })
    .then(data => {
      const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
      return state =>
        state
          .set('totalCount', totalCount)
          .update('users', users => (reset ? fromJS([]) : users))
          .update('users', users => users.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray()));
    })
    .catch(() => state => state.set('totalCount', 0).set('users', List()));
}

const getQueryParam = (startIndex, stopIndex, filter) => ({
  start_index: startIndex,
  stop_index: stopIndex,
  ...filter,
});

markAsSideEffect(addUser);
export function addUser() {
  return this.modal.open(AddUserModal, {
    controller: this.controller,
    alert: this.alert,
    customRoles: this.state.get('customRoles'),
  });
}

export async function createUser(user) {
  return buildersService.createUser(user).then(() => state => {
    setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
    this.alert.success({
      message: 'Invite successfully sent. User will receive an invite to join TradeTrax.',
    });
    return state.set('users', List());
  });
}

export function updateUser(user, properties, extraProps) {
  const userId = user.get('_id');

  return buildersService.updateUser(properties, { params: { userId } }).then(() => state => {
    const index = state.get('users').findIndex(user => user.get('_id') === userId);
    return state.updateIn(['users', index], user => user.merge({ ...properties, ...extraProps }));
  });
}

markAsSideEffect(reactivateUser);
export function reactivateUser(user) {
  const status = user.get('statusBeforeInactive') || 'active';
  return this.controller
    .updateUser(user, { status })
    .then(() => {
      this.alert.success({ message: 'User successfully reactivated.' });
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem reactivating this user. Please try again later.' });
    });
}
markAsSideEffect(deactivateUser);
export async function deactivateUser(user) {
  const statusBeforeInactive = user.get('status');
  const { isAccept } = await this.modal.open(DeactivateUserModal);

  if (isAccept) {
    this.controller
      .updateUser(user, { status: 'inactive' }, { statusBeforeInactive })
      .then(() => {
        this.alert.success({
          message: 'User successfully deactivated and revoked access to Tradetrax. You can reactivate user later. ',
        });
      })
      .catch(() => {
        this.alert.error({ message: 'There was a problem deactivating this user. Please try again later.' });
      });
  }
}

markAsSideEffect(rowAction);
export async function rowAction(action, user) {
  if (action === 'reactivate') this.controller.reactivateUser(user);
  if (action === 'deactivate') this.controller.deactivateUser(user);
}

export function readCustomRoles() {
  return buildersService
    .listRoles({}, { query: { active: 'true' } })
    .then(response => state => state.set('customRoles', viewModel(response)));
}

function viewModel(response) {
  return fromJS(
    response.map(role => ({
      name: role.name,
      _id: role._id,
    }))
  );
}
