import React from 'react';
import styled from 'styled-components';
import { Hint, Input } from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';
import { debounce } from 'lodash';
import { renderMenu, TrxTypeahead } from '.';
import { colors } from '../theme';

export const GlobalSearchTypeahead = ({ navigateToJob, navigateToTask, expanded, ...props }) => {
  const [menuStatus, setMenuStatus] = React.useState('');
  React.useEffect(() => {
    if (menuStatus) {
      const isInsideTypeahead = node => {
        if (!node) return false;
        if ('global-search-bar' === node.id) return true;
        if (node.classList.contains('rbt-input-main')) return true;
        return isInsideTypeahead(node.parentElement);
      };
      const onClick = ({ target }) => {
        if (!isInsideTypeahead(target)) {
          props.onInputChange('');
          setMenuStatus('');
        }
      };
      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [menuStatus, setMenuStatus]);
  const isEmpty = menuStatus === 'open' && !props.options.length;
  const emptyLabel = isEmpty ? 'No Matches Found' : <MenuStatusMessage status={menuStatus} />;

  return (
    <StyledTypeahead
      {...props}
      id="global-search-bar"
      name="global-search-bar"
      bsSize="lg"
      placeholder="Search Jobs and Tasks"
      icon="magnifying-glass"
      emptyLabel={emptyLabel}
      isMenuShown={menuStatus}
      labelKey={option => option?.name}
      renderMenu={customRenderMenu}
      renderInput={renderInput}
      filterBy={option => option}
      selected={[]}
      onInputChange={onInputChange(props.onInputChange, setMenuStatus)}
      onChange={([option]) => {
        if (!option) return props.onInputChange('');
        const { type, jobId, taskId } = option;
        const isJob = type === 'job';
        const isTask = type === 'task';
        if (isJob) navigateToJob(jobId);
        if (isTask) navigateToTask(jobId, taskId);
        props.onInputChange('');
        setMenuStatus('');
      }}
      expanded={expanded}
    />
  );
};

const customRenderMenu = props => (results, menuProps) => renderMenu(props, menuProps, results, menuItemLabel);

const renderInput = ({ inputRef, referenceElementRef, ...inputProps }) => (
  <Hint shouldSelect={(shouldSelect, e) => e.key === 'Enter'}>
    <Input
      {...inputProps}
      className="form-control rbt-input rounded"
      ref={node => {
        inputRef(node);
        referenceElementRef(node);
      }}
    />
  </Hint>
);

const menuItemLabel = (option, { labelKey }) => {
  if (option.paginationOption) return <div className="btn-link text-center">{option.label || 'See more...'}</div>;
  const { type } = option;
  const isJob = type === 'job';
  const isTask = type === 'task';
  const hasLotNumber = !!option.lotNumber;
  const taskText = hasLotNumber ? `${option.jobName} - #${option.lotNumber}` : option.jobName;
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-between w-100">
        <span className="d-inline-block text-truncate w-75">{`${labelKey(option)} `}</span>
        <span className="text-muted font-size-12 mt-1">{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      </div>
      <div className="w-100 d-flex flex-column">
        {isJob && hasLotNumber && (
          <span className="text-muted font-size-12 text-truncate d-inline-block">{`# ${option.lotNumber}`}</span>
        )}
        {isTask && <span className="text-muted font-size-12 text-truncate d-inline-block mw-100">{taskText}</span>}
      </div>
    </div>
  );
};

const MenuStatusMessage = ({ status }) => {
  return status === 'infoMsg' ? (
    <span className="text-gray-400">Enter at least 2 Characters to see results</span>
  ) : (
    <>
      <StyledSpinner animation="grow" />
      <span className="text-gray-400">Loading...</span>
    </>
  );
};

const onInputChange = (search, setMenuStatus) => text => {
  const textLength = text.length;
  setMenuStatus(textLength === 0 ? '' : textLength < 2 ? 'infoMsg' : 'loading');
  const onSearch = debounce(() => {
    search(text);
    if (textLength >= 2) setMenuStatus('open');
  }, 700);

  onSearch();
};

const StyledTypeahead = styled(TrxTypeahead)`
  width: ${props => (props.expanded ? '250px' : '400px')};
  transition: width 0.3s ease-in-out;
  @media (min-width: 992px) {
    width: ${props => (props.expanded ? '57%' : '420px')};
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 420px;
  }

  ${({ isMenuShown }) => `
    #global-search-bar {
      display: ${isMenuShown ? 'block' : 'none'} !important;
      transform: translate3d(0px, 49px, 0px) !important;
    }
  `}
`;

const StyledSpinner = styled(Spinner)`
  margin-bottom: 0.4rem !important;
  margin-right: 0.25rem !important;
  width: 0.5rem !important;
  height: 0.5rem !important;
  color: ${colors.gray400} !important;
`;
