import React from 'react';
import { fromJS } from 'immutable';
import { useController } from '@tradetrax/web-common';
import { useAppContext } from 'app/App.context';
import * as actions from './HomeownerActions';
import { useFilterContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';

const emptyState = fromJS({
  homeowners: [],
  totalCount: 10,
  builderAssignees: [],
  communities: [],
});

export function HomeownerContext() {
  const loaderRef = React.useRef();
  const appContext = useAppContext();
  const filterContext = useFilterContext(FILTER_NAME.HOME_OWNERS);
  const { filterState } = filterContext;
  const filter = filterState.get('values');
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    filterState,
    loaderRef,
    checkValidEmail,
  });

  React.useEffect(() => {
    controller.getFilterOptions();
  }, [controller]);

  const [oldFilter, setOldFilter] = React.useState(filter);

  React.useEffect(() => {
    if (filter !== oldFilter) {
      setOldFilter(filter);
      controller.loadMoreRows({ reset: true }).then(() => {
        setTimeout(() => loaderRef.current?.resetLoadMoreRowsCache(true), 1);
      });
    }
  }, [controller, filter, oldFilter]);

  return {
    state,
    controller,
    loaderRef,
    filterContext,
    filter,
  };
}

const checkValidEmail = existingEmails => (email, setError) => {
  const emailExisis = existingEmails.filter(existing => existing.toLowerCase() === email.toLowerCase());
  if (emailExisis.size > 0 && setError) setError('email', { type: 'notMatch', message: 'Email already exists' });
};
