import { fromJS, Repeat } from 'immutable';
import { markAsSync, markAsSideEffect, EMPTY_ROW } from '@tradetrax/web-common';
import { AddHomeownerModal } from './AddHomeownerModal';
import { ResendInviteModal } from './ResendInviteModal';
import { buildersService } from 'services';

markAsSync(loadMoreRows);
export function loadMoreRows(state, { reset = false, startIndex = 0, stopIndex = 9 }) {
  const filter = this.filterState.get('values').toJS();
  const query = getQueryParam(startIndex, stopIndex, filter);
  buildersService.listHomeowners({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    this.controller.dispatch([
      state =>
        state
          .set('totalCount', totalCount)
          .update('homeowners', homeowners => (reset ? fromJS([]) : homeowners))
          .update('homeowners', homeowners =>
            homeowners.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
          ),
    ]);
  });
  const data = Repeat(EMPTY_ROW, stopIndex - startIndex);

  return state.update('homeowners', homeowners =>
    homeowners.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(data).toArray())
  );
}

markAsSync(invalidateFilter);
export function invalidateFilter(state) {
  setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
  return state.merge(fromJS({ homeowners: [], totalCount: 10, maxCount: 10 }));
}

markAsSideEffect(assignBuilder);
export function assignBuilder(assignee, home) {
  const index = this.state.get('homeowners').indexOf(home);
  const path = ['homeowners', index, 'builderAssignee'];
  const currentAssignee = home.get('builderAssignee');
  const builderAssigneeId = assignee ? assignee._id : null;
  const userId = home.get('_id');

  buildersService
    .updateHomeowner({ builderAssigneeId }, { params: { userId } })
    .then(response => {
      const { builderAssignee } = response;
      this.controller.dispatch([state => state.setIn(path, fromJS(builderAssignee))]);
      this.controller.getFilterOptions();
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem assigning a Builder to this Homeowner. Please try again.' });
      this.controller.dispatch([state => state.setIn(path, currentAssignee)]);
    });
}

markAsSideEffect(resendInvite);
export async function resendInvite(home, index) {
  const { isAccept } = await this.modal.open(ResendInviteModal, { homeowner: home });
  if (!isAccept) return;

  this.controller.dispatch([state => state.setIn(['homeowners', index, 'sendingInvite'], true)]);

  buildersService
    .sendInviteHomeowner({}, { params: { userId: home.get('_id') }, query: { email: true } })
    .then(() => {
      this.alert.success({
        message:
          'Invite successfully sent. The Homeowner will receive an invite to join the TradeTrax Homeowner Portal.',
      });
      this.controller.dispatch([state => state.setIn(['homeowners', index, 'sendingInvite'], false)]);
    })
    .catch(() => {
      this.alert.error({
        message: 'There was a problem sending this invite to the Homeowner. Please try again.',
      });
      this.controller.dispatch([state => state.setIn(['homeowners', index, 'sendingInvite'], false)]);
    });
}

markAsSideEffect(openAddHomeownerModal);
export async function openAddHomeownerModal() {
  const jobs = await buildersService.readJobsWithoutHomeowners();
  const existingEmails = this.state.get('homeowners').map(homeowner => homeowner.get('email'));
  const { isAccept, form } = await this.modal.open(AddHomeownerModal, {
    jobs,
    checkValidEmail: this.checkValidEmail(existingEmails),
  });

  if (!isAccept) return;

  const { job } = form;
  buildersService
    .createHomeowner({
      firstName: form.name,
      lastName: form.lastName,
      email: form.email,
      phone: form.phone || null,
      jobId: job[0]._id,
      builderAssigneeId: form.builderAssigneeId,
      customerManagerId: form.customerManagerId,
    })
    .then(fromJS)
    .then(response => {
      this.alert.success({ message: 'Homeowner successfully added.' });
      this.controller.getFilterOptions();
      this.controller.dispatch([
        state =>
          state
            .update('homeowners', homeowners => homeowners.push(response))
            .update('totalCount', totalCount => totalCount + 1),
      ]);
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem creating this Homeowner. Please try again.' });
    });
}

markAsSideEffect(getFilterOptions);
export async function getFilterOptions() {
  try {
    const [builderAssignees, communities] = await Promise.all([
      buildersService.listHomeownerBuilderAssignees().then(fromJS),
      buildersService.listHomeownerCommunities().then(fromJS),
    ]);
    this.controller.dispatch([
      state => state.set('builderAssignees', builderAssignees).set('communities', communities),
    ]);
  } catch (error) {
    return error;
  }
}

function getQueryParam(startIndex, stopIndex, filter) {
  const query = { start_index: startIndex, stop_index: stopIndex };
  const { status, communityIds, assigneeAccounts } = filter;
  if (!filter) return query;
  if (status) {
    // TODO: why trying to delete status? if `query.status` doesn't exists.
    if (status === 'all') delete query.status;
    else query.status = status;
  }
  if (communityIds.length) query.communityIds = communityIds;
  if (assigneeAccounts.length) query.builderAssigneeIds = assigneeAccounts;

  return query;
}
