import React from 'react';
import cn from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Datepicker, useTimeAgo } from '@tradetrax/web-common';
import { PrimaryButton, SecondaryButton } from '@tradetrax/web-common/lib/Button';
import { Impact } from '@tradetrax/web-common/lib/Task/Impact';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';
import {
  datepickerFormat,
  mongoToTrx,
  formatISO,
  getMinEndDate,
  isDifferentYear,
  mongoToTextYearComparison,
} from '@tradetrax/web-common/lib/utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { getProposedDate } from './TasksAffectedContext';
import { CycleTimeImpact } from '@tradetrax/web-common/lib/CycleTimeImpact/RootCause/CycleTime.impact';
import { UpdateRequestState } from '@tradetrax/web-common/lib/CycleTimeImpact/UpdateRequest.state';

export function UpdateRequestView({ state, controller, accept }) {
  const { task, tasksAffected, jobDelay } = state.toObject();
  const { changeRequest, predecessors } = task.toObject();
  const urState = UpdateRequestState({
    task,
    jobDelay,
    isDueWeather: task.getIn(['changeRequest', 'reasons'])?.includes('weather'),
  });
  const hasPredecessors = predecessors.size > 0;
  const isJobDelayed = (jobDelay || 0) > 0;
  const isTaskDelayed = (task.get('daysBehind') || 0) > 0;
  const numberTasksHeld = tasksAffected.reduce((acc, task) => (task.get('isHeld') ? ++acc : acc), 0);
  const numberTaskAffected = tasksAffected.size - numberTasksHeld;
  const { isStartUR, date } = getProposedDate(task);
  const isClickable = numberTaskAffected || numberTasksHeld;

  const onAcceptDateChange = () => {
    const { payload } = urState;
    const tasksHeld = tasksAffected
      .filter(task => task.get('isHeld') && task.get('isDirectSuccesor'))
      .map(task => task.get('id'));

    accept({ isDeclined: false, isStartUR, tasksHeld, proposedDate: date, ...payload });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Update Request
          <h6 className="font-weight-bold">{`Task: ${task.get('name')}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Impact>
          <li>
            <label>{`Proposed ${isStartUR ? 'Start' : 'Finish'}`}</label>
            <ProposedDateSelect
              task={task}
              isStartUR={isStartUR}
              proposedDate={date}
              controller={controller}
              areTasksHeldEdited={numberTasksHeld > 0}
            />
          </li>
          <li>
            <label>{`Expected ${isStartUR ? 'Start' : 'Finish'}`}</label>
            <span className={cn({ 'text-danger': isTaskDelayed })}>
              {mongoToTextYearComparison(task.get(isStartUR ? 'startDate' : 'expectedFinishDate'))}
            </span>
          </li>
          {/* <li>
            <span>Duration</span>
            <span className="text-gray-400">{task.get('durationDays')}</span>
          </li> */}
          <li className={cn({ 'd-none': !numberTasksHeld })}>
            <span>Tasks Held</span>
            <span className="text-gray-400">{numberTasksHeld}</span>
          </li>
          <li
            onClick={() => isClickable && controller.showTasksAffectedView()}
            className={cn({
              clickable: isClickable,
              'text-danger': numberTaskAffected,
            })}
          >
            <label className={cn('mb-0 mr-3', { 'font-weight-bold': numberTaskAffected === 0 })}>Tasks Affected</label>
            <span className={cn('d-flex align-items-center', { 'text-gray-400': !numberTaskAffected })}>
              {numberTaskAffected}{' '}
              <FontAwesomeIcon
                icon="chevron-right"
                className={cn('font-size-12 ml-1 text-gray-400', { 'd-none': !isClickable })}
              />
            </span>
          </li>
          <CycleTimeImpact updateRequestState={urState} />
          <li className="border-top">
            <span className="font-weight-bold">Due to Weather</span>
            <input
              type="checkbox"
              checked={urState.isDueWeather}
              onChange={() => urState.setValue('isDueWeather', !urState.isDueWeather)}
            />
          </li>
        </Impact>
        <div className="font-size-14  d-flex flex-row mb-3">
          <span className="text-gray-400 mr-1">Update request by: </span>
          <span className="text-dark">{changeRequest.get('createdByName')}</span>
          <span className="text-gray-400 mr-3 ml-auto"> {useTimeAgo(changeRequest.get('createdOn'))}</span>
        </div>
        {isStartUR && hasPredecessors && !isJobDelayed && (
          <span className="font-size-14 mt-3 text-gray-400">
            By confirming this Update Request will change the Exp. Start Date for the task and will also result in{' '}
            <strong>all Predecessor relationships being removed</strong>. Please double-check before confirming.
          </span>
        )}
        {isJobDelayed && (!hasPredecessors || !isStartUR) && (
          <span className="font-size-14 mt-3 text-gray-400">
            By confirming this Update Request will <strong>delay the finish date for the Job</strong>. Please
            double-check before confirming.
          </span>
        )}
        {isStartUR && hasPredecessors && isJobDelayed && (
          <span className="font-size-14 mt-3 text-gray-400">
            By confirming this Update Request will result in{' '}
            <strong>all Predecessor relationships being removed</strong>. It will also{' '}
            <strong>delay the finish date for the Job</strong>. Please double-check before confirming.
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton variant="secondary" onClick={() => accept({ isDeclined: true, isStartUR })}>
          Decline
        </PrimaryButton>
        <PrimaryButton onClick={onAcceptDateChange} disabled={urState.hasErrors} className="ml-4">
          Accept Date Change
        </PrimaryButton>
      </Modal.Footer>
    </>
  );
}

const ProposedDateSelect = ({ task, controller, isStartUR, proposedDate, areTasksHeldEdited }) => {
  const selectedDate = datepickerFormat(mongoToTrx(proposedDate));
  const minEndDate = getMinEndDate(task.get('startDate'));
  const [isYearDisplayed, setIsYearDisplayed] = React.useState(isDifferentYear(selectedDate));
  const [newProposedDate, setNewProposedDate] = React.useState(null);

  const [date1, date2] = isStartUR ? ['startDate', 'lateStartDate'] : ['expectedFinishDate', 'lateEndDate'];
  const expectedDate = task.get(date1) ? datepickerFormat(mongoToTrx(task.get(date1))) : '';
  const lateDate = task.get(date2) || undefined;
  const criticalDate = new Date(lateDate);
  const showPopover = areTasksHeldEdited && !!newProposedDate;

  const updateProposedDate = date => {
    const proposedDate = date || newProposedDate;
    controller.updateProposedDate(task.get('id'), formatISO(proposedDate));
    setNewProposedDate(null);
  };

  const popover = (
    <div className="pt-4 pl-3 pb-3 pr-1">
      <p>By selecting a new date, the Tasks you've held will be reset.</p>
      <div className="d-flex flex-row justify-content-end">
        <Button
          variant="link"
          className="text-secondary font-weight-bold mr-3"
          onClick={() => setNewProposedDate(null)}
        >
          Cancel
        </Button>
        <SecondaryButton variant="outline-primary" onClick={() => updateProposedDate()}>
          Select and Reset
        </SecondaryButton>
      </div>
    </div>
  );

  return (
    <div style={{ width: '130px' }}>
      <PopoverStickOnHover placement="bottom-start" popover={popover} show={showPopover} width={340}>
        <Datepicker
          name="propFinishDate"
          selected={newProposedDate || selectedDate}
          readOnly={showPopover}
          minDate={isStartUR ? null : minEndDate}
          dateFormat={`MMM dd${isYearDisplayed ? ', yyyy' : ''}`}
          onChange={date => {
            if (date) {
              setIsYearDisplayed(isDifferentYear(date));
              if (areTasksHeldEdited) setNewProposedDate(date);
              else updateProposedDate(date);
            }
          }}
          onFocus={() => setIsYearDisplayed(true)}
          onBlur={({ target }) => setIsYearDisplayed(isDifferentYear(Date.parse(target.value)))}
          dayClassName={date => criticalProposedDateClass(date, selectedDate, expectedDate, criticalDate)}
          renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, selectedDate)}
          calendarClassName="react-datepicker__critical"
        >
          <WarningCriticalMsg showMsg={showWarningCriticalMsg(selectedDate, criticalDate)} />
        </Datepicker>
      </PopoverStickOnHover>
    </div>
  );
};
