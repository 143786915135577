import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { StatusDataOnTime } from '../OnTime';
import { ChipStatus, STATUS_MAP } from '../ChipStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import construction from './images/construction.svg';
import { MilestonType } from './MilestoneType';
import { getUserDomain } from '../utils';

export const ProgressTracker = ({ job, user }) => {
  const {
    name,
    communityName,
    builderAccountName,
    lot,
    lotNumber,
    progress,
    status,
    completed,
    statusData,
  } = job.toObject();
  const lotNumerData = lot ? lot : lotNumber ? lotNumber : null;
  const { isHomeowner } = getUserDomain(user);

  return (
    <JobProgressContainer isHomeowner={isHomeowner}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div>
            <h3 className="font-weight-bold">{name}</h3>
            {lotNumerData && <span className="text-muted mr-1">LOT #{lotNumerData}.</span>}
            {communityName && !isHomeowner && <span>{communityName}</span>}
            {builderAccountName && isHomeowner && <span>{builderAccountName}</span>}
          </div>
          {!isHomeowner && (
            <div className="ml-5 d-flex">
              <ChipStatus {...STATUS_MAP[status]} />
              {statusData.get('daysDifference') > 0 && statusData.get('targetStatus') !== 'on-schedule' && (
                <StatusDataOnTime data={job.get('statusData')} className="mt-1 ml-3" />
              )}
            </div>
          )}
        </div>
      </div>

      <ProgressBar milestones={progress} user={user} completed={completed} />
    </JobProgressContainer>
  );
};

const JobProgressContainer = styled.div`
${props => `
  background-color: ${props.isHomeowner ? colors.white : colors.pearl};
`}
  border: 1px solid ${colors.gray};
  margin-top: 42px;
  padding: 36px 42px;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
`;

const ProgressBar = ({ milestones, user, completed }) => {
  const { isHomeowner } = getUserDomain(user);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
    };
  }, [ref]);

  const totalElementsWidth = calculateProgressBarWidth(milestones, isHomeowner);
  const widthProgressBarSection = (width - totalElementsWidth) / (milestones.size - 1);
  const lastIndex = getLastMilestoneReference(milestones);
  const milestoneStatus = milestones.getIn([lastIndex, 'status']);

  return (
    <ProgressBarContainer ref={ref} data-testid="progress-bar-container">
      {milestones.size < 1 ? (
        <NoMilestonesSet isHomeowner={isHomeowner} />
      ) : !completed && !isHomeowner ? (
        <IncompleteMilestonesSet />
      ) : (
        milestones.map((item, index) => {
          const addProgressBarSection = index < milestones.size - 1;
          const isBeforeMilestone = index < lastIndex;
          const isLastMilestone = index === lastIndex;
          const nextMilestone = item?.get('status');
          const isLastMilestoneJob = index === milestones.size - 1;

          return (
            <MilestonType
              milestone={item}
              width={widthProgressBarSection}
              addProgressBarSection={addProgressBarSection}
              milestones={milestones}
              isNextMilestoneFromRef={index === lastIndex + 1}
              statusMilestoneRef={milestoneStatus}
              widthFillBar={getWidthFillBar(isBeforeMilestone, isLastMilestone, nextMilestone)}
              isHomeowner={isHomeowner}
              isFirstMilestone={index === 0}
              isLastMilestone={isLastMilestoneJob}
              key={index}
            />
          );
        })
      )}
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export const getWidthFillBar = (isBeforeMilestone, isLastMilestone, nextMilestone) => {
  if (isBeforeMilestone) return '100%';
  if (isLastMilestone && nextMilestone === 'completed') return '50%';
  return 0;
};

const calculateProgressBarWidth = (milestones, isHomeowner) => {
  let widthElements = 0;
  for (let milestone of milestones) {
    const { status } = milestone.toObject();
    if (isHomeowner) widthElements += status === 'in-progress' ? 90 : 18;
    else widthElements += status === 'in-progress' ? 15 : 12;
  }
  return widthElements;
};

export const getLastMilestoneReference = milestones => {
  return milestones.findLastIndex(milestone => {
    const { status } = milestone.toObject();
    return status === 'in-progress' || status === 'completed';
  });
};

export const NoMilestonesSet = ({ isHomeowner }) => {
  return (
    <div className="d-flex flex-column align-items-center w-100 position-relative">
      {isHomeowner ? (
        <>
          <img src={construction} width={150} className="mt-5" />
          <span className="font-size-16 text-gray-400 mt-3 mb-5 pb-3">There's no Home progress set yet</span>
        </>
      ) : (
        <div
          className="font-size-16 text-gray-400 d-flex flex-column  align-items-center position-absolute"
          style={{ top: '-70px' }}
        >
          <span>There's no Job progress set yet</span>
          <span>Contact the TradeTrax team for support.</span>
        </div>
      )}
    </div>
  );
};

export const IncompleteMilestonesSet = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 text-muted">
      <WarningIcon icon="triangle-exclamation" />
      <p className="m-0">Incomplete Job Progress Milestones.</p>
      <p>Contact the TradeTrax team for support.</p>
    </div>
  );
};

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${colors.yellow100};
  font-size: 30px;
  margin-bottom: 5px;
`;
