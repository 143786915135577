import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AutoSizer, Column, InfiniteLoader, Table } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, renderCell, EMPTY_ROW } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { HomeownerContext } from './HomeownerContext';
import { useAppContext } from 'app/App.context';
import navigate from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { handleTableScrollStickyColumn2, scrollbarStyles } from '@tradetrax/web-common/lib/utils';
import { isEmpty } from 'lodash';

const COL_WIDTHS = {
  span: 32,
  job: 16 * 14,
  lot: 7 * 16,
  community: 12 * 16,
  assignee: 14 * 16,
  status: 160,
};
const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export function HomeownersPage() {
  const { appState } = useAppContext();
  const { state, controller, loaderRef, filterContext, filter } = HomeownerContext();
  const { totalCount, homeowners } = state.toObject();
  const { communities, builderAssignees } = state.toObject();
  const lookups = { communities, builderAssignees };
  const { isFiltering } = filterContext;
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-content-between mx-4 my-4">
        <h4 className="font-weight-bold">Homeowners</h4>
        <div className="d-flex flex-row align-items-center">
          <PrimaryButton onClick={controller.openAddHomeownerModal}>Add Homeowner</PrimaryButton>
          <FilterImplementation filterContext={filterContext} lookups={lookups} className="ml-3" />
        </div>
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!homeowners.get(index)}
        loadMoreRows={({ startIndex, stopIndex }) => controller.loadMoreRows({ startIndex, stopIndex, filter })}
        rowCount={totalCount}
        ref={loaderRef}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <ScrollDiv ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1200 ? width : 1200;

                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowClassName={({ index }) => {
                      const rowData = homeowners.get(index);
                      return rowData === null || isEmpty(rowData) ? 'loading' : '';
                    }}
                    rowGetter={({ index }) => homeowners.get(index) || EMPTY_ROW}
                    headerClassName={`${totalCount ? '' : 'd-none'}`}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="250px">
                        <EmptyState
                          icon="person-shelter"
                          title={isFiltering ? 'No Matches Found' : 'No Homeowners Added'}
                          body={`When ${
                            isFiltering ? "there's a Homeowner that meets the filters" : 'a Homeowner is added'
                          }, it will appear here.`}
                        />
                      </EmptyStateCenterContainer>
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="Homeowner"
                      dataKey="firstName"
                      minWidth={250}
                      width={minWidth - WIDTHS - COL_WIDTHS.span}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const status = rowData.get('status');
                        const isInactive = status === 'deactivated';
                        return (
                          <Link
                            className="main-link"
                            to={navigate.to.HomeownerProfile({ userId: rowData.get('_id') }, false)}
                          >
                            <div className="font-size-16 text-truncate">
                              <span className={cn({ 'text-gray-200': isInactive })}>
                                {isInactive && <FontAwesomeIcon icon="ban" className="mr-2 text-danger" />}
                                {`${cellData} ${rowData.get('lastName')}`}
                              </span>
                            </div>
                          </Link>
                        );
                      })}
                    />
                    <Column
                      label="Job"
                      dataKey="jobs"
                      width={COL_WIDTHS.job}
                      cellRenderer={renderCell(({ cellData }) => {
                        const [job] = cellData;
                        return (
                          <Link to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}>
                            <div className="text-truncate">{job.get('name')}</div>
                          </Link>
                        );
                      })}
                    />
                    <Column
                      label="LOT #"
                      dataKey="jobs"
                      width={COL_WIDTHS.job}
                      cellRenderer={renderCell(({ cellData }) => {
                        const [job] = cellData;
                        const lotNumber = job.get('lotNumber');
                        return (
                          <span className={cn('font-size-16', { 'text-gray-200': !lotNumber })}>
                            {lotNumber || 'Not Set'}
                          </span>
                        );
                      })}
                    />
                    <Column
                      label="Community"
                      dataKey="jobs"
                      width={COL_WIDTHS.community}
                      cellRenderer={renderCell(({ cellData }) => {
                        const [job] = cellData;
                        return (
                          <Link to={navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false)}>
                            <div className="text-truncate">{job.get('communityName')}</div>
                          </Link>
                        );
                      })}
                    />
                    <Column
                      label="Builder Assignee"
                      dataKey="builderAssignee"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        return (
                          <AssigneeSelect
                            index={rowIndex}
                            currentUser={cellData}
                            users={appState.getIn(['account', 'users'])}
                            options={appState.getIn(['account', 'usersActive'])}
                            loggedUserId={appState.getIn(['user', '_id'])}
                            onChange={assignee => controller.assignBuilder(assignee, rowData)}
                          />
                        );
                      })}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const isSending = rowData.get('sendingInvite');
                        if (cellData === 'invitation-sent')
                          return (
                            <button
                              className="btn btn-link font-size-14 p-0 m-0"
                              disabled={isSending}
                              onClick={() => controller.resendInvite(rowData, rowIndex)}
                            >
                              {isSending && (
                                <span
                                  className="spinner-border spinner-border-sm mb-1 mr-1"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {isSending ? 'Sending...' : 'Resend Invite'}
                            </button>
                          );
                        return (
                          <span>
                            {cellData === 'active' && 'Active'}
                            {cellData === 'inactive' && 'Deactivated'}
                            {cellData === 'deactivated' && 'Deactivated'}
                            {cellData === 'invitation-accepted' && 'Invite Accepted'}
                            {cellData === 'profile-created' && 'Profile Created'}
                          </span>
                        );
                      })}
                    />

                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </ScrollDiv>
        )}
      </InfiniteLoader>
    </>
  );
}

const ScrollDiv = styled.div`
  flex: 1 1 auto;
  ${scrollbarStyles}
`;
