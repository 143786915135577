import React, { useEffect, useRef } from 'react';
import { UsersPageContext } from './UsersPageContext';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { lastActivity, handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';
import { TableRowDropDown, PrimaryButton, EmptyState, EMPTY_ROW, renderCell, Initials } from '@tradetrax/web-common';
import navigate, { Paths } from 'app/navigate';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { isEmpty } from 'lodash';

const COL_WIDTHS = {
  span: 16 * 2,
  type: 16 * 12,
  status: 16 * 12,
  lastActivity: 16 * 12,
  action: 16 * 2,
};
const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const Users = () => {
  const { state, controller, loaderRef, currentUserId, roleUser, filterContext } = UsersPageContext();
  const users = state.get('users');
  const usersTotalCount = state.get('totalCount');
  const profileLink = navigate.to.UserProfile;
  const isInactive = user => user.get('status') === 'inactive';
  const isNotSet = user => !user.get('email') && !user.get('phone');
  const filter = filterContext.filterState.get('values');
  const scrollDivRef = useRef(null);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);

  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [users]);

  return (
    <>
      <div className="mx-4 my-4 d-flex flex-row justify-content-between">
        <h4 className="font-weight-bold">Users</h4>
        <PrimaryButton data-testid="btn-add-user" onClick={() => controller.addUser()}>
          Add User
        </PrimaryButton>
      </div>
      <div className="d-flex mx-4 justify-content-end ">
        <FilterImplementation filterContext={filterContext} />
      </div>

      <InfiniteLoader
        isRowLoaded={({ index }) => !!users.get(index)}
        loadMoreRows={({ startIndex, stopIndex }) => controller.loadMoreRows({ startIndex, stopIndex, filter })}
        rowCount={usersTotalCount}
        ref={loaderRef}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div ref={scrollDivRef} style={{ flex: '1 1 auto', overflowX: 'auto', overflowY: 'hidden' }}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1150 ? width : 1150;
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyTable stickyColumn2"
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={usersTotalCount}
                    rowClassName={({ index }) => {
                      const user = users.get(index);
                      return user === null || isEmpty(user) ? 'loading' : '';
                    }}
                    rowGetter={({ index }) => users.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyState
                        icon="users"
                        title={`No Users ${filter.get('status') === 'all' ? 'Added' : 'Found'}`}
                        body={`${
                          filter.get('status') === 'all'
                            ? 'When a User is added, they will appear here.'
                            : 'There are no users that match this filter.'
                        }`}
                      />
                    )}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label="User"
                      dataKey="firstName"
                      minWidth={350}
                      width={width > 1150 ? width - WIDTHS : 350}
                      cellRenderer={renderCell(({ rowData }) => {
                        const name = `${rowData.get('firstName')} ${rowData.get('lastName')}`;
                        const isUserInactive = isInactive(rowData);
                        const link =
                          rowData.get('_id') === currentUserId
                            ? Paths.MyProfile
                            : profileLink({ userId: rowData.get('_id') }, false);
                        return (
                          <div className="d-flex flex-row">
                            <Initials name={rowData.get('initials') || name} size={50} />
                            <div className="d-flex flex-column  text-truncate">
                              <span
                                className={cn('d-flex align-items-center', {
                                  'deactivated-user': isUserInactive,
                                })}
                              >
                                {isUserInactive && <FontAwesomeIcon icon="ban" className="mr-1" />}
                                <Link className={cn('main-link  w-100', { inactive: isUserInactive })} to={link}>
                                  <div className="text-truncate">{name}</div>
                                </Link>
                              </span>
                              <span
                                className={cn('text-truncate', {
                                  'text-gray-200': isUserInactive || isNotSet(rowData),
                                })}
                                style={{ maxWidth: '100%' }}
                              >
                                {rowData.get('email') || rowData.get('phone') || 'Not Set'}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    />
                    <Column
                      label="Type"
                      width={COL_WIDTHS.type}
                      dataKey="role"
                      cellRenderer={renderCell(({ cellData }) => (
                        <span>{cellData === 'manager' ? 'Account Manager' : ''}</span>
                      ))}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ rowData, cellData: status, rowIndex }) => {
                        const isSending = rowData.get('sendingInvite') === true;
                        if (status === 'invitation-sent')
                          return (
                            <button
                              className="btn btn-link font-size-14 p-0 m-0"
                              disabled={isSending}
                              onClick={() => controller.resendInvite(rowData, ['users', rowIndex])}
                            >
                              {isSending && (
                                <span
                                  className="spinner-border spinner-border-sm mb-1 mr-1"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {isSending ? 'Sending...' : 'Resend Invite'}
                            </button>
                          );
                        return (
                          <span>
                            {status === 'active' && 'Active'}
                            {status === 'inactive' && 'Deactivated'}
                            {status === 'invitation-accepted' && 'Invite Accepted'}
                            {status === 'profile-created' && 'Profile Created'}
                          </span>
                        );
                      })}
                    />
                    <Column
                      label="Last Activity"
                      width={COL_WIDTHS.lastActivity}
                      dataKey="lastActivity"
                      cellRenderer={renderCell(({ cellData }) => {
                        if (!cellData) return <span className="with-sublabel text-muted">Hasn’t signed in</span>;

                        return <span>{lastActivity(cellData)}</span>;
                      })}
                    />
                    <Column
                      label=""
                      dataKey=""
                      width={COL_WIDTHS.action}
                      cellRenderer={renderCell(({ rowData }) => {
                        if (rowData.get('_id') === currentUserId) return null;
                        if (roleUser === 'regular' && rowData.get('role') === 'manager') return null;

                        return (
                          <TableRowDropDown
                            onSelect={action => {
                              controller.rowAction(action, rowData);
                            }}
                          >
                            {isInactive(rowData) ? (
                              <TableRowDropDown.IconItem
                                icon="circle-plus"
                                eventKey="reactivate"
                                label="Reactivate"
                                className="text-success"
                              />
                            ) : (
                              <TableRowDropDown.IconItem
                                icon="ban"
                                eventKey="deactivate"
                                label="Deactivate"
                                className="text-danger"
                              />
                            )}
                          </TableRowDropDown>
                        );
                      })}
                    />
                    <Column width={COL_WIDTHS.span} dataKey="" label="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
