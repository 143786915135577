import { useEffect } from 'react';
import { fromJS } from 'immutable';
import moment from 'moment';
import { TasksGraph } from '@tradetrax/tasks-util';
import { markAsSideEffect, useController, useCommunities, useTaskTypes } from '@tradetrax/web-common';
import { useInfiniteScroll } from '@tradetrax/web-common/lib/ToDo';
import { emptyFilter } from '@tradetrax/web-common/lib/Task/TasksFilter';
import { emptyFilter as emptyRecentActivityFilter } from '@tradetrax/web-common/lib/Filters/RecentActivityFilter';
import * as dashboardActions from '@tradetrax/web-common/lib/Dashboard/DashboardActions';
// import { useChannel, CHANNELS } from '@tradetrax/web-common/lib/useRealTime';
import { useFiltersContext, FILTER_NAME } from '@tradetrax/web-common/lib/Filters/Filters.context';
import { buildersService as dashboardService } from 'services';
import { useAppContext } from 'app/App.context';
import { TasksAffectedModal } from 'app/workflows/TasksAffected';

const emptyToDo = {
  startToday: 0,
  finishToday: 0,
  updateToday: 0,
};
const loadingCard = {
  id: '123456789123456789123456',
  isLoading: true,
  job: { id: '123456789123456789123456' },
};
const emptyCardsLoading = [...Array(3)].map(() => loadingCard);

export const emptyState = fromJS({
  tasksUpdateRequest: emptyCardsLoading,
  tasksOverdue: emptyCardsLoading,
  toDo: emptyToDo,
  selectedDate: moment().format('YYYY-MM-DD'),
  upcomingTasks: [{ empty: true }],
  recentActivities: { isLoading: true, totalCount: 0, activities: [] },
  filter: {
    toDo: emptyFilter,
    upcoming: emptyFilter,
    requests: emptyFilter,
    recentActivity: emptyRecentActivityFilter,
  },
});

const todoQuery = { isAssignedToSelfAccount: true };
const actions = { ...dashboardActions, openTasksAffectedModal };

export function DashboardPageContext() {
  const appContext = useAppContext();
  const { filtersState } = useFiltersContext();
  const [state, controller] = useController(actions, emptyState, {
    ...appContext,
    dashboardService,
    todoQuery,
    isBuilder: true,
    filtersState,
  });
  const user = appContext.appState.get('user');
  const toDoFilter = filtersState.getIn([FILTER_NAME.TO_DO, 'values']);
  const selectedDate = filtersState.getIn([FILTER_NAME.TO_DO, 'sessionValues', 'selectedDate']);
  const requestsFilter = filtersState.getIn([FILTER_NAME.UR_OVERDUE, 'values']);
  const upcomingFilter = filtersState.getIn([FILTER_NAME.UPCOMING, 'values']);
  const recentActivityFilter = filtersState.getIn([FILTER_NAME.RECENT_ACTIVITY, 'values']);
  const containerRef = useInfiniteScroll(controller.loadMoreRowsRecentActivity, 100);
  const communities = useCommunities(dashboardService);
  const taskTypes = useTaskTypes(dashboardService);

  useEffect(() => {
    controller.readUpdateRequestsDashboard();
    controller.readTasksOverdueDashboard();
  }, [controller, requestsFilter]);

  useEffect(() => {
    controller.readUpcomingTasks();
  }, [controller, upcomingFilter]);

  useEffect(() => {
    controller.getRecentActivities();
  }, [controller, recentActivityFilter]);

  useEffect(() => {
    controller.loadStartTodayTasks();
    controller.loadFinishTodayTasks();
    controller.loadUpdateTodayTasks();
    controller.loadWithConfirmationRequestTasks();
  }, [controller, toDoFilter, selectedDate]);

  // Commented while waiting for improved tech approach
  // const refreshCounters = React.useMemo(
  //   () => foo => {
  //     loadToDoSection();
  //     loadRequestsOverdueAndUpcoming();
  //   },
  //   [loadToDoSection, loadRequestsOverdueAndUpcoming]
  // );
  // useChannel(CHANNELS.ACCOUNT_TASK, user.get('accountId'), refreshCounters);

  return { state, controller, user, dashboardService, containerRef, communities, taskTypes };
}

markAsSideEffect(openTasksAffectedModal);
export async function openTasksAffectedModal(task, props) {
  const jobId = task.getIn(['job', 'id']);
  const [job, tasks] = await Promise.all([
    dashboardService.readJob({}, { params: { jobId } }),
    dashboardService.readJobTasks({}, { params: { jobId }, query: { includeStageTasks: true } }),
  ]);
  const bulkContext = props.bulkContext;
  const tasksGraph = new TasksGraph(tasks, job);
  const { isCancel, isDeclined, ...rest } = await this.modal.open(TasksAffectedModal, {
    task,
    tasksGraph,
    ...props,
  });

  if (isCancel) return;

  const { isStartUR, tasksHeld, proposedDate, isDueWeather = false, taskIdRootCause, builderRootCause } = rest;
  const reasons = isDueWeather ? ['weather'] : [];

  if (props.isUpdateRequest) {
    const isTrade = false;
    if (isDeclined) {
      this.controller.cancelExpFinishDateRequest({ task, isTrade, bulkContext });
    } else {
      const params = {
        task,
        tasksHeld,
        proposedDate,
        isTrade,
        bulkContext,
        reasons,
        taskIdRootCause,
        builderRootCause,
      };
      if (isStartUR) this.controller.acceptStartDateUpdateRequest(params);
      else this.controller.acceptExpFinishDateUpdateRequest(params);
    }
  } else if (props.isOverdue) {
    this.controller.acceptDateOverdueRequest(
      jobId,
      task,
      proposedDate,
      tasksHeld,
      bulkContext,
      reasons,
      taskIdRootCause,
      builderRootCause
    );
  }
}
