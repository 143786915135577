import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, UsersTags } from '@tradetrax/web-common';
import { ProgressTracker } from '@tradetrax/web-common/lib/ProgressTracker';
import { LoadingPlaceHolder } from './LoadingPlaceHolder';
import navigate, { Paths } from 'app/navigate';

export function HomeownerProfile({ context }) {
  const { user, controller, state } = context;
  const { homeownerDetails, job, isLoading } = state.toObject();
  const { firstName, lastName, email, phone, builderAssignee, status } = homeownerDetails.toObject();
  const isInactive = status === 'deactivated';
  const showBanner = status !== 'active' && !isInactive && status !== 'invitation-accepted';

  return (
    <>
      <div className="mx-4">
        <Breadcrumb className="mt-4" tag="nav">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.Homeowners }}>
            Homeowners
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{`${firstName} ${lastName}`}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-flex justify-content-end">
          <Link className="main-link" to={() => navigate.from.HomeownerProfile.to.HomeownerDetails({}, false)}>
            <FontAwesomeIcon icon="circle-user" className="mr-2" />
            Homeowner Details
          </Link>
        </div>

        <h4 className="font-weight-bold">{`${firstName} ${lastName}`}</h4>
        <HomeownerDataContainer className="d-flex mt-4">
          <div className="d-flex flex-column">
            <span className="text-muted mb-1 font-size-14">Homeowner</span>
            <Link className="main-link" to={() => navigate.from.HomeownerProfile.to.HomeownerDetails({}, false)}>
              <span>
                {isInactive && <FontAwesomeIcon icon="ban" className="mr-2 text-danger" />}
                <span className={cn({ 'text-gray-200': isInactive })}>{`${firstName} ${lastName}`}</span>
              </span>
            </Link>
          </div>
          <div className="d-flex flex-column ">
            <span className="text-muted mb-1 font-size-14">Email</span>
            <span className="d-block text-truncate pr-3">{email ? email : 'Not set'}</span>
          </div>
          <div className="d-flex flex-column ">
            <span className="text-muted font-size-14 mb-1">Phone</span>
            {phone ? <span>{phone}</span> : <span className="text-gray-200">Not Set</span>}
          </div>
          <div className="d-flex flex-column ">
            <UsersTags
              className="mr-5 w-9rem space-between-tags"
              maxTags={1}
              title="Builder Assignee"
              users={
                builderAssignee ? [{ name: builderAssignee.get('name'), status: builderAssignee.get('status') }] : []
              }
              onShowMore={false}
            />
          </div>
        </HomeownerDataContainer>
      </div>
      <Separator />
      <div className="mx-4 pb-4">
        <div className="d-flex  mt-5 align-items-center position-relative">
          <h5 className="font-weight-bold ">Job Progress</h5>
          {showBanner && (
            <Banner>
              The Homeowner doesn't have access to the portal.
              <button
                className="btn btn-link font-size-14 p-0 ml-1 mb-1"
                disabled={status === 'sending-invite'}
                onClick={() => controller.sendInvite()}
              >
                {status === 'invitation-sent' && 'Resend Invite.'}
                {status === 'profile-created' && 'Send Invite.'}
                {status === 'sending-invite' && 'Sending...'}
              </button>
            </Banner>
          )}
        </div>
        <div>{isLoading ? <LoadingPlaceHolder /> : job.size > 0 && <ProgressTracker job={job} user={user} />}</div>
      </div>
    </>
  );
}

const Separator = styled.div`
  display: block;
  margin-top: 35px;
  height: 1px;
  border: 1px solid ${colors.gray500};
  width: 98%;
`;

const Banner = styled.div`
  padding: 10px 16px;
  background-color: ${colors.blue100};
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  text-align: center;
  line-height: 1;
  margin-left: 40px;
  min-width: 320px;
  @media (min-width: 1200px) {
    margin-left: 0;
    padding: 10px 80px;
    width: 590px;
  }
`;

const HomeownerDataContainer = styled.div`
  flex-wrap: wrap;
  max-width: 1000px;
  & > div {
    width: 33%;

    @media (min-width: 1200px) {
      width: 25%;
    }
  }
  & > div:nth-child(4) {
    margin-top: 15px;
    @media (min-width: 1200px) {
      margin-top: 0;
    }
  }
`;
